<template>
    <div>
        <BookingEditPrices></BookingEditPrices>
    </div>
</template>
  
<script>

import BookingEditPrices from "@core/components/prices/PricesHelp.vue";

export default {
    components: {
        BookingEditPrices
    },
}

</script>
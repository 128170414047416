<template>
    <div>
     <b-card>
<p><span style="font-size: medium;"><u><strong>Formula to calculate the Companys prices</strong></u></span></p>
<br>
<p><span style="font-size: small;">IF trip type is round trip then do these two actions:</span></p>
<p><span style="font-size: small;"><span style="color: #333333;"><strong>BaseEachWay</strong></span> = BaseEachWayFee * 2;</span></p>
<p><span style="font-size: small;"><strong>TotalMiles</strong>=Miles / 2</span></p>
<br>
<p><span style="font-size: small;"><span style="color: #333333;"><strong>Total</strong></span>=BaseEachWay + (AddtionalStops * AdditionalStopsFee) + (WaitingTime * WaitTimePerHourFee) + (TotalMiles * MileageFee) </span></p>
</br></br>
<p><span style="font-size: medium;"><u><strong>Formula to calculate the Selfpay prices</strong></u></span></p>

<p><span style="font-size: small;">Total=(AdditionalMiles * 1.25) + (AdditionalStopFees * AdditionalStops) + (WaitingTime * WaitTimePerHourFee) </span></p>

</br></br>
<p><span style="font-size: medium;"><strong>Values Fees</strong></span></p>
<p>The fees are got from the default values or the information set in the booking information.</p>
<p>The default values are set in these pages</p>

<p>Settings-&gt;Prices Selfpay</p>
<p><a href="https://admin.myamera.com/all-prices-selfpaids">https://admin.myamera.com/all-prices-selfpaids</a></p>

<img src="@/assets/images/others/page_se.png" alt="" width="997" height="494" name="Image2" />

<p class="mt-4">Settings-&gt;Prices Companys</p>
<p><a href="https://admin.myamera.com/all-prices-corporate-account">https://admin.myamera.com/all-prices-corporate-account</a></p>
<img src="@/assets/images/others/page_co.png" alt="" width="997" height="494" name="Image2" />













<p><br /> </p>
     </b-card>
    </div>
</template>
  
<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { getMessageError } from "@core/utils/utils";
import { doCalculations } from '@core/utils/distances';
import { toFixed, convertToNumber } from "@core/utils/numbers_utils";
import {
    getMenuOptionsTripType,
    getMenuOptionsServiceType,
} from "@core/utils/menus";
import { getItemWaitingTime, getItemsAdditionalStops } from '@core/utils/additionalServices'

import {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormSelect,
    BFormDatepicker,
    BFormTimepicker,
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BTh,
    BTbody,
    BTfoot,
    BForm,
    BFormCheckbox,
    BCard,
} from "bootstrap-vue";

export default {
    components: {
        FormWizard,
        TabContent,
        BRow,
        BCol,
        BButton,
        BFormGroup,
        BFormInput,
        BFormRadio,
        BFormSelect,
        BFormDatepicker,
        BFormTimepicker,
        vSelect,
        BTableSimple,
        BThead,
        BTr,
        BTd,
        BTh,
        BTbody,
        BTfoot,
        ToastificationContent,
        ValidationProvider,
        ValidationObserver,
        BForm,
        BFormCheckbox,
        BCard,
    },
}

</script>
  

  